@font-face {
  font-family: 'TrajanSansPro';
  src: url('assets/fonts/TrajanSansPro/TrajanSansPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/TrajanSansPro/TrajanSansPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/TrajanSansPro/TrajanSansPro-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('assets/fonts/TrajanSansPro/TrajanSansPro-Regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/TrajanSansPro/TrajanSansPro-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.html {
  font-size: 16px;
  box-sizing: border-box;
}

.App {
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(./assets/images/backside.jpg) no-repeat center center;
  height: 100vh;
  /* backdrop-filter: blur(5px);
  -webkit-filter: blur(5px); */
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date {
  color: white;
  font-family: 'TrajanSansPro';
  font-size: 2rem;
  margin: 1rem;
}

.tag {
  color: #FF3571;
  line-height: 1.5em;
  font-family: 'TrajanSansPro';
  font-size: 2rem;
  border: 2px solid white;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
}

.btn-price {
  font-family: 'TrajanSansPro';
  color: #FF3571;
  background: white;
  padding: 0.5rem 4rem;
  font-size: 2rem;
  border-radius: 2rem;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

/* flex center */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}